import axios from "axios";
import { toast } from "react-toastify";
import {
  PATIENTVISIT_CREATE_FAIL,
  PATIENTVISIT_CREATE_REQUEST,
  PATIENTVISIT_CREATE_SUCCESS,
  PATIENTVISIT_LIST_FAIL,
  PATIENTVISIT_LIST_REQUEST,
  PATIENTVISIT_LIST_SUCCESS,
  PATIENT_CREATE_FAIL,
  PATIENT_CREATE_REQUEST,
  PATIENT_CREATE_SUCCESS,
  PATIENT_LIST_FAIL,
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_SUCCESS
} from "../constants/patientConstants";

export const createPatient = (patient) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_CREATE_REQUEST,
    });

    const {} = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `${patient.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/patient/create/`,
      patient,
      config
    );
    dispatch({
      type: PATIENT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listPatient = (token) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_LIST_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient/list/`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    console.log("DATA FROM REDUX: ", data);
    dispatch({
      type: PATIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const deletedPatientList = (token) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_LIST_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient/deleted-patients`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    console.log("DATA FROM REDUX: ", data);
    dispatch({
      type: PATIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const deletedVisitList = (token) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_LIST_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient/deleted-patient-visits`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: PATIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const createPatientVisit = (patient) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENTVISIT_CREATE_REQUEST,
    });

    const {} = getState();
    // get all the keys of patient and append them in form data
    const formData = new FormData();
    Object.keys(patient).forEach((key) => {
      formData.append(key, patient[key]);
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `${patient.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/patient/createvisit/${patient.patient_id}/`,
      formData,
      config
    );

    if (data.code === 200) {
      toast.success("Patient Visit Created Successfully", {
        position: "top-right",
      });
    } else {
      toast.error("Something bad happened", {
        position: "top-right",
      });
    }

    dispatch({
      type: PATIENTVISIT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Patient Visit Creation Failed", {
      position: "top-right",
    });
    dispatch({
      type: PATIENTVISIT_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listPatientVisit = (token) => async (dispatch) => {
  try {
    dispatch({ type: PATIENTVISIT_LIST_REQUEST });
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/doc/docs/`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: PATIENTVISIT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PATIENTVISIT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
