import React, {useState, useEffect} from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Row, Col, Table } from 'react-bootstrap'

import { listDoctors } from '../actions/doctorActions'

function Test() {

  const dispatch = useDispatch()
  const doctorList = useSelector(state => state.doctorList)
  const { error, loading, doctors } = doctorList


  useEffect(() => {
      dispatch(listDoctors())

  }, [dispatch])


    
  return (
    <div>Test</div>
  )
}

export default Test