import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Plotly from "react-plotly.js";
const HeatMapGraph = () => {
  const params = useParams();
  const token = useSelector((state) => state.loginData.token);
  const [heatmapData, setHeatmapData] = useState(null);

  useEffect(() => {
    if (!heatmapData) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/pwelch_plot/${params.id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
              },
            }
          );

          if (response.data.code === 401) {
            localStorage.clear();
            window.location.reload();
          } else if (response.data.message !== "error") {
            const heatmapData = response.data.data.updated_Sxx;

            setHeatmapData(heatmapData);
          } else {
            console.log(response.data.message);
          }
        } catch (error) {
          console.error("Error fetching heatmap data:", error);
        }
      };

      fetchData();
    }
  }, [heatmapData, params.id, token]);
  const plotlyData = [
    {
      z: heatmapData,
      type: "heatmap",
      colorscale: "Jet",
      zmin: -5,
      zmax: 15,
      colorbar: {
        title: "Heatmap Values",
        tickmode: "array",
        tickvals: [-5, 0, 5, 10, 15],
        ticktext: ["-5", "0", "5", "10", "15"],
      },
    },
  ];

  return (
    <div className="heatmap-container">
      {heatmapData ? (
        <div className="heatmap-plot">
          <Plotly
            data={plotlyData}
            layout={{
              xaxis: { title: "Time" },
              yaxis: { title: "Frequency" },
            }}
          />
        </div>
      ) : (
        <div>Loading heatmap data...</div>
      )}
    </div>
  );
};

export default HeatMapGraph;
