import React, { useState } from "react";
import companyLogo from "../../assets/img/logosync.png";
import { Alert } from "react-bootstrap";
import Footer from "../Footer";
import "../assets/css/login.css";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/logins";
import axios from "axios";

import { ReactComponent as ShowPasswordIcon } from "../../assets/img/svg/show-password.svg";
import { ReactComponent as HidePasswordIcon } from "../../assets/img/svg/hide-password.svg";
import { ReactComponent as LeftArrow } from "../../assets/img/svg/left-triangle.svg";
import { ReactComponent as TickIcon } from "../../assets/img/svg/tick.svg";
import { ReactComponent as IconTi } from "../../assets/img/svg/circle-tick.svg";
import { ReactComponent as IconTin } from "../../assets/img/svg/not-circle.svg";
import { ReactComponent as CrossIcon } from "../../assets/img/svg/cross.svg";

function Register() {
  const [validated, setValidated] = useState(false);
  
  const history = useHistory();
  const dispatch = useDispatch();
  const [registerAttempt, setRegisterAttempt] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [passwordType, setPasswordType] = useState('password');
  const togglePasswordType = () => setPasswordType((prevState) =>  prevState === 'password' ? 'text' : 'password');
  const onPasswordChange = (e) => {
    let passwordStrength = checkPasswordStrength(e.target.value);
    setPasswordStrength(passwordStrength);
  }
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [numericCase, setNumericCase] = useState(false);
  const [specialCase, setSpecialCase] = useState(false);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };


 




  const handleBlur = () => {
    setIsFocused(false);
  };

  const checkPasswordStrength = (password) => {
    // Define regex patterns for different character types
    var patterns = {
        lowerCase: /[a-z]/,
        upperCase: /[A-Z]/,
        numbers: /[0-9]/,
        specialChars: /[^A-Za-z0-9\s]/
    };

    // Check if password meets the criteria
    var hasLowerCase = patterns.lowerCase.test(password);
    var hasUpperCase = patterns.upperCase.test(password);
    var hasNumbers = patterns.numbers.test(password);
    var hasSpecialChars = patterns.specialChars.test(password);

    setLowerCase(hasLowerCase);
    setUpperCase(hasUpperCase);
    setNumericCase(hasNumbers);
    setSpecialCase(hasSpecialChars);

    // Calculate strength based on criteria met
    var strength = 0;
    strength += hasLowerCase ? 1 : 0;
    strength += hasUpperCase ? 1 : 0;
    strength += hasNumbers ? 1 : 0;
    strength += hasSpecialChars ? 1 : 0;

    // Calculate password length bonus
    var lengthBonus = Math.min(2, password.replace(/\s/g, '').length / 8);
    strength += lengthBonus;

    // Return strength as a percentage
    console.log(Math.min(Math.round((strength / 6) * 100), 100));
    return Math.min(Math.round((strength / 6) * 100), 100);
  }

  function handleSubmit(e) {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    e.preventDefault();
    // e.stopPropagation();
    const register_credentials = {
      email: e.target[0].value,
      password: e.target[1].value,
      firstName: e.target[2].value,
      lastName: e.target[3].value,
      medicalId: e.target[4].value,
      hospitalId: e.target[5].value,
      confirmPassword: e.target[1].value,




      // ------------

    
  





      // ------------------
    };

    console.log(register_credentials);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/register`, register_credentials)
      .then((res) => {
        console.log("RESPONSEL ", res);
        if (res.data.status === true) {
          setRegisterAttempt(true);
          console.log("success");
          history.push({
            pathname: "/login",
          });
        } else {
          setRegisterAttempt(false);
          console.log("failed");
          
        }
      });
  }

  return (
    <div>
      {" "}
      <>
        <section className="login-main">
          <div className="container h-100">
            <div className="row justify-content-center p-0 m-0 h-100">
              <div className="col-xxl-5 col-lg-5 col-md-12 col-sm-12 py-5 h-100">
                <div className="content">
                  <div className="div-image">
                    <img
                      src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg"
                      className="logo-image-class"
                      alt="company logo"
                    />
                  </div>
                  <div className="card card-border-radius w-100 p-lg-5 p-sm-2">
                    <div className="card-title">
                      <h2 className="p-3 pb-1">Create your Account</h2>
                    </div>
                    <div className="card-body">
                    <form
      className={`needs-validation ${validated ? 'was-validated' : ''}`}
      noValidate
      onSubmit={handleSubmit}
    >
                      {/* <form onSubmit={handleSubmit} className="was-validated  needs-validation" novalidate> */}
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="form-label login-form-label"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control login-form-input"
                            id="email"
                            required
                          />
                          {/* <div className="valid-feedback">Valid.</div> */}
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        <div className="mb-4 password-field">
                          <div className="d-flex justify-content-between">
                            <label
                              htmlFor="password"
                              className="form-label login-form-label"
                            >
                              Password
                            </label>
                          </div>
                          <input
                            type={passwordType}
                            className="form-control login-form-input"
                            id="password"
                            required
                            onChange={(e) => onPasswordChange(e)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                          {
                            passwordType === 'password' ?
                            <ShowPasswordIcon onClick={togglePasswordType} className="eye-icon" /> :
                            <HidePasswordIcon onClick={togglePasswordType} className="eye-icon" />
                          }
                         
                          {isFocused && 
                            <div className="password-checker">
                            
                             <p className="margins-b">Password Requirements</p>
                            <div className="check-list">
                              <div className={`check ${upperCase ? 'line-through' : ''}`}>
                                {upperCase ? <IconTi />  : <IconTin/> }
                                One Upper Case
                              </div>
                              <div className={`check ${lowerCase ? 'line-through' : ''}`}>
                                {lowerCase ? <IconTi /> : <IconTin />}
                                One Lower Case
                              </div>
                              <div className={`check ${numericCase ? 'line-through' : ''}`}>
                                {numericCase ? <IconTi /> : <IconTin />}
                                One Numeric
                              </div>
                              <div className={`check ${specialCase ? 'line-through' : ''}`}>
                                {specialCase ? <IconTi /> : <IconTin />}
                                One Special Character
                              </div>
                            </div>
                            <div className="strength-container">
                              <div className="instructions">Strength: &nbsp;
                                {passwordStrength <= 50 && <span className="weak">Weak</span>}
                                {(passwordStrength > 50 && passwordStrength <= 80) && <span className="medium">Medium</span>}
                                {passwordStrength > 80 && <span className="strong">Strong</span>}
                              </div>
                              <div className="strengths">
                                <div className={`strength ${(passwordStrength <= 50 || passwordStrength > 50) && 'weak-bg'}`} />
                                <div className={`strength ${((passwordStrength > 50 && passwordStrength <= 80) || passwordStrength > 80) && 'medium-bg'}`} />
                                <div className={`strength ${passwordStrength > 80 && 'strong-bg'}`} />
                              </div>
                            </div>
                            <LeftArrow className="arrow" />
                          </div>
                          }
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="mb-4 res-m">
                            <label
                              htmlFor="firstname"
                              className="form-label login-form-label"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control login-form-input"
                              id="firstname"
                              required
                            />
                            {/* <div className="valid-feedback">Valid.</div> */}
                            <div className="invalid-feedback">
                              Please fill out this field.
                            </div>
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="lastname"
                              className="form-label login-form-label"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control login-form-input"
                              id="lastname"
                              required
                            />
                            {/* <div className="valid-feedback">Valid.</div> */}
                            <div className="invalid-feedback">
                              Please fill out this field.
                            </div>
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="medicalid"
                            className="form-label login-form-label"
                          >
                            Medical ID
                          </label>
                          <input
                            type="text"
                            className="form-control login-form-input"
                            id="medicalid"
                            required
                          />
                          {/* <div className="valid-feedback">Valid.</div> */}
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="hospital"
                            className="form-label login-form-label"
                          >
                            Hospital
                          </label>
                          <input
                            type="text"
                            className="form-control login-form-input"
                            id="hospital"
                            required
                          />
                          {/* <div className="valid-feedback">Valid.</div> */}
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>

                        <div className="d-grid">
                          <button
                            type="submit"
                            className="btn text-light main-bg btn-style"
                          >
                            <b>CREATE ACCOUNT</b>
                          </button>
                        </div>

                        {!registerAttempt && (
                          <div style={{ paddingTop: "15px" }}>
                            <Alert variant="danger">
                              <p>Something went wrong!</p>
                            </Alert>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </div>
  );
}

export default Register;
