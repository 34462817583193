import React, {useState, useRef, useEffect} from 'react'

import Slider from "@mui/material/Slider";
import axios from "axios";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { Chart } from "react-chartjs-2";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

import tableViewIcon from "../../assets/img/svg/graph-table-view.svg";
import tileViewIcon from "../../assets/img/svg/graph-tile-view.svg";
import fastBackwardIcon from "../../assets/img/svg/fast-backward-icon.svg";
import backwardIcon from "../../assets/img/svg/backward-icon.svg";
import playIcon from "../../assets/img/svg/play-icon.svg";
import fastForwardIcon from "../../assets/img/svg/fast-forward-icon.svg";
import forwardIcon from "../../assets/img/svg/forward-icon.svg";
import { useHistory } from "react-router-dom";
import HeatMapGraph from './HeatMapGraph';

import './recording2.css';

const tabsListDummyData = [1,2,3,4,5,6,7,8,9];

const Recording2Updated = (props) => {

  const params =  useParams();
  const history = useHistory();


  const [startRange, setStartRange] = useState(0);
  const [endRange, setEndRange] = useState(0);

  const [value, setValue] = React.useState([0, 1000]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStartRange(newValue[0]);
    setEndRange(newValue[1]);
    // console.log(newValue);
  };

  //for filters above main graph
  const [lffValue, setLffValue] = useState("1Hz");
  const [hffValue, setHffValue] = useState("70Hz");
  const [notchValue, setNotchValue] = useState("60Hz");
  const [alert, setAlert] = useState();

  //for main graph
  const [myChart, setMyChart] = useState(null);
  const [myChartCH2, setMyChartCH2] = useState(null);

  
  const [dataCount, setDataCount] = useState(0);
  const [dataLength, setDataLength] = useState(null);
  const [eegData, setEegData] = useState([]);
  const [patientData, setPatientData] = useState();
  const [genderData, setGenderData] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState();


  const [conditionData, setConditionData] = useState();

  const [filteredEegData, setFilteredEegData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const myRef = useRef();
  const myRefCH2 = useRef();


  // for Pwelch Plot
  const [showPwelchPlot, setShowPwelchPlot] = useState(0);
  const [pwelchChart, setPwelchChart] = useState(null);
  const [pwelchEegData, setPwelchEegData] = useState([]);
  const [pwelchFilteredData, setPwelchFilteredData] = useState(null);
  const [pwelchIsFilter, setPwelchIsFilter] = useState(0);
  const [pwelchLoading, setPwelchLoading] = useState(true);
  const [pwelchAlert, setPwelchAlert] = useState();
  const myRef2 = useRef();
  const isMounted = useRef(false);

  // for Spectogram
  const [heatMapUrl, setHeatMapUrl] = useState();
  const [isHeatMap, setIsHeatMap] = useState(false);

  const token = useSelector((state) => state.loginData.token);
  Chart.register(zoomPlugin);

  const [requestStatus, setRequestStatus] = useState(false);

  //For Modal  to save graph values
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isDataSaved, setIsDataSaved] = useState(false);

  const [graphView, setGraphView] = useState('tile');
  const [activeTab, setActiveTab] = useState('notes');
  const toggleGraphView = (view) => setGraphView(view);
  const toggleActiveTab = (tab) => setActiveTab(tab);

  function handleSubmit(e) {
    e.preventDefault();
    setIsDataSaved(true);
    if (startRange < endRange) {
      setRequestStatus(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/save_recordings`,
          {
            patient_visit_id: params.id,
            start_time: startRange,
            end_time: endRange,
            clinical_condition: e.target[0].value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        )
        .then((response) => {
          setRequestStatus(false);
          if (response.data.code === 401) {
            localStorage.clear();
            window.location.reload();
          } else {
            if (response.data.status === false) {
              setIsDataSaved(false);
              toast.error(response.data.data);
            } else {
              setIsDataSaved(false);
              toast.success("Data saved successfully");
              setShow(false);
            }
          }
        });
    } else {
      toast.error("Start range should be less than End range");
    }
  }
  // Second UseEffect to be executed, it populate the EEG data on the graph
  useEffect(() => {
    if (isMounted.current) {
      if (eegData.length === 0) {
        return;
      }
      if (myChart != null) {
        myChart.destroy();
      }
      // debugger;
      setMyChart(
        new Chart(myRef.current, {
          type: "line",
          data: {
            labels: eegData.length === 0 ? [] : eegData.length[dataCount],
            datasets: [
              {
                label: "CH1 Voltage",
                data: eegData.length === 0 ? [] : isFilter ? filteredEegData.CH1_Voltage[dataCount] : eegData.CH1_Voltage[dataCount],
                backgroundColor: "rgba(0, 0, 0, 0)", 
                borderColor: "rgba(0, 0, 0, 1)", 
                borderWidth: 0.4,
                pointRadius: 0,
              },
            ]
          },
          width: 400,
          height: 1000,
          options: {
            scales: {
              x: {
                ticks: {
                  stepSize: 1
                },
                title: {
                  display: true,
                  text: "Time (seconds)",
                },
              },
              y: {
                title: {
                  display: true,
                  text: [
                    "EEG (uV)", 
                    `${(patientData?.location || "N/A").split('-')[0] || ""}`,
                  ],
                },
              },
            },
            normalized: true,
            maintainAspectRatio: false,
            animation: false,
            spanGaps: true,
            datasets: {
              line: {
                pointRadius: 0, 
              },
            },
            elements: {
              point: {
                radius: 0, 
              },
            },
            plugins: {
              decimation: {
                enabled: true,
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: "x",
                  rangeMin: { x: 0 },
                  rangeMax: { x: 100 },
                },
                zoom: {
                  wheel: {
                    enabled: true,
                    mode: "xy",
                    rangeMin: { x: 0 },
                    rangeMax: { x: 100 },
                  },
                  pinch: {
                    enabled: true,
                  },
                  mode: "x",
                },
              },
            },
          },
        })
      );
    }
  }, [eegData, dataCount, isFilter]);


  useEffect(() => {
    if (isMounted.current) {
      if (eegData.length === 0) {
        return;
      }
      if (myChartCH2 != null) {
        myChartCH2.destroy();
      }
      setMyChartCH2(
        new Chart(myRefCH2.current, {
          type: "line",
          data: {
            labels: eegData.length === 0 ? [] : eegData.length[dataCount],
            datasets: [
              {
                label: "CH2 Voltage",
                data: eegData.length === 0 ? [] : isFilter ? filteredEegData.CH2_Voltage[dataCount] : eegData.CH2_Voltage[dataCount],
                backgroundColor: "rgba(0, 0, 0, 0)", 
                borderColor: "rgba(0, 0, 0, 1)", 
                borderWidth: 0.4,
                pointRadius: 0,
              },
            ]
          },
          width: 400,
          height: 1000,
          options: {
            scales: {
              x: {
                ticks: {
                  stepSize: 10
                },
                title: {
                  display: true,
                  text: "Time (seconds)",
                },
              },
              y: {
                title: {
                  display: true,
                  text: [
                    "EEG (uV)", 
                    `${(patientData?.location || "N/A").split('-')[1] || ""}`,
                  ],
                },
              },
            },
            normalized: true,
            maintainAspectRatio: false,
            animation: false,
            spanGaps: true,
            datasets: {
              line: {
                pointRadius: 0, // disable for all `'line'` datasets
              },
            },
            elements: {
              point: {
                radius: 0, // default to disabled in all datasets
              },
            },
            plugins: {
              decimation: {
                enabled: true,
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: "x",
                  rangeMin: { x: 0 },
                  rangeMax: { x: 100 },
                },
                zoom: {
                  wheel: {
                    enabled: true,
                    mode: "xy",
                    rangeMin: { x: 0 },
                    rangeMax: { x: 100 },
                  },
                  pinch: {
                    enabled: true,
                  },
                  mode: "x",
                },
              },
            },
          },
        })
      );
    }
  }, [eegData, dataCount, isFilter]);


  // First UseEffect to be executed and will fill the array of EEG data
  useEffect(() => {
    try {
    } catch (error) {
      console.log(error);
    }
    const getData = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}/eeg_file/${params.id}`;

      try {
        const {data} = await axios.get(url,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        const actualData = data.data;
        if (data.code === 401) {
          localStorage.clear();
          window.location.reload();
        } else {
          isMounted.current = true;
          if (data.message !== "error") {
            const patient_gender = +actualData.patient_data?.gender
            const genderDisplay =
              patient_gender === 1
                ? "M"
                : patient_gender === 2
                ? "F"
                : "N/A";
                const patient_condition = +actualData.patient_data?.clinical_condition;
                const conditionDisplay = patient_condition === 1
                                          ? "Seizure"
                                          : patient_condition === 2
                                          ? "Concussions"
                                          : patient_condition === 3
                                          ? "Stroke"
                                          : "N/A";
            const timestamp = actualData.patient_data?.timestamp;

            if (timestamp) {
                const [date, time] = timestamp.split(' ');
                setDate(date); // "2024-03-21"
                setTime(time); // "16:40:37"
            }
            setGenderData(genderDisplay)
            setConditionData(conditionDisplay)
            setPatientData(actualData.patient_data);
            setEegData(actualData);
            setDataLength(actualData.length.length);
            setLoading(false);
            setShowPwelchPlot(1);
            
          } else {
            setAlert(data.message);
          }
        }
      } catch (error) {
        toast.error("No relevant file EEG file found for the given patient", {autoClose: 3000, position: "top-right"});
        setLoading(false);
        // setAlert("No relevant file EEG file found for the given patient");
      }
    };
    // debugger;
    getData();
  }, []);

  const applyFilters = () => {
    setIsHeatMap(false);
    setIsDataSaved(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/filters`,
        {
          patient_visit_id: params.id,
          LFF: lffValue,
          HFF: hffValue,
          Notch: notchValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setIsDataSaved(false);
        if (response.data.code === 401) {
          localStorage.clear();
          window.location.reload();
        } else {
          if (response.data.status === false) {
          } else {
            setLoading(true);
            setIsFilter(false);
            setFilteredEegData([]);
            setFilteredEegData(response.data.data);
            setIsFilter(true);
            setLoading(false);
            
            setPwelchFilteredData(response.data.data);
            axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/pwelch_filters`,
              {
                patient_visit_id: params.id,
                LFF: lffValue,
                HFF: hffValue,
                Notch: notchValue,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
              }
              )
              .then((response) => {
                if (response.data.code === 401) {
                  localStorage.clear();
                  window.location.reload();
                } else {
                  if (response.data.status === false) {
                  } else {
                    setPwelchIsFilter(0);
                    setPwelchFilteredData([]);
                    setPwelchFilteredData(response.data.data);
                    setHeatMapUrl(response.data.data.plot_url);
                    setIsHeatMap(true);
                    setPwelchIsFilter(1);
                  }
                }
              });
          }
        }
      });
  };

  return (
    <div>
        <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {alert ? (
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Data Not Available</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>No data was found for this patient visit </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                history.goBack();
              }}
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      ) : (
        <div className='recording2'>
            <div className='recording-graph-panel'>
                <div className='info-container'>
                    <div className='personal-info'>
                        <h1>{patientData?.name}</h1>
                        <div className='info-wrapper'>
                            <div className='info'>
                                <p>Patient#: </p>
                                <p>{patientData?.id_number || "N/A"}</p>
                            </div>
                            <div className='info'>
                                <p>Age / Gender: </p>
                                <p>{patientData?.age || "N/A"} / {genderData || "N/A"}</p>
                            </div>
                            <div className='info'>
                                <p>Condition: </p>
                                <p>{conditionData || "N/A"}</p>
                            </div>
                            <div className='info'>
                                <p>Date of Visit: </p>
                                <p>{date || "N/A"}</p>
                            </div>
                            <div className='info'>
                                <p>Time of Visit: </p>
                                <p>{time || "N/A"}</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className='btns-container'>
                        <button className="button btn-invert">Add Notes</button>
                        <button className="button btn-default">Share Report</button>
                    </div>
                </div>
                <div className='eeg-graph-panel'>
                    <div className='eeg-header'>
                        <div className='header-stats'>
                            <div className='header-stat'>
                                <p>Display: <span>10s</span></p>
                            </div>
                            <div className='header-stat'>
                                <p>Scale: <span>10uv/mm</span></p>
                            </div>
                            {/* laga howa mal */}
                            <div className='header-stat'>
                                <p>LFF: </p>
                                <div
                                    className="dropdown"
                                    style={{ paddingLeft: "4px" }}
                                  >
                                    <button
                                      className="btn
                                      green-btn2 btn-sm dropdown-toggle"
                                      type="button"
                                      id="dropdownMenu2"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      defaultValue="1Hz"
                                      style={{ fontWeight: "bold", fontSize: "10" }}
                                    >
                                      {lffValue}
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenu2"
                                    >
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setLffValue("N/A")}
                                      >
                                        N/A
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setLffValue("1Hz")}
                                      >
                                        1Hz
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setLffValue("5Hz")}
                                      >
                                        5Hz
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setLffValue("15Hz")}
                                      >
                                        15Hz
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setLffValue("20Hz")}
                                      >
                                        20Hz
                                      </button>
                                    </div>
                                  </div>
                            </div>
                            <div className='header-stat'>
                                <p>HFF: </p>
                                <div
                                    className="dropdown"
                                    style={{ paddingLeft: "4px" }}
                                  >
                                    <button
                                      className="btn green-btn2 btn-sm dropdown-toggle"
                                      type="button"
                                      id="dropdownMenu2"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      defaultValue="HFF4"
                                      style={{ fontWeight: "bold", fontSize: "10" }}
                                    >
                                      {hffValue}
                                    </button>

                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenu2"
                                    >
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setHffValue("N/A")}
                                      >
                                        N/A
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setHffValue("15Hz")}
                                      >
                                        15 Hz
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setHffValue("30Hz")}
                                      >
                                        30 Hz
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setHffValue("40Hz")}
                                      >
                                        40 Hz
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() => setHffValue("70Hz")}
                                      >
                                        70 Hz
                                      </button>
                                    </div>
                                  </div>
                            </div>
                            <div className='header-stat'>
                                <p>Notch: </p>
                                <div
                                    className="dropdown"
                                    style={{ paddingLeft: "4px" }}
                                  >
                                    <button
                                      className="btn btn-sm green-btn2 dropdown-toggle"
                                      type="button"
                                      id="dropdownMenu2"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      defaultValue="HFF4"
                                      style={{ fontWeight: "bold", fontSize: "10" }}
                                    >
                                      {notchValue}
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenu2"
                                    >
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={(e) => {
                                          setNotchValue("N/A");
                                        }}
                                      >
                                        N/A
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={(e) => {
                                          setNotchValue("50Hz");
                                        }}
                                      >
                                        50 Hz
                                      </button>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={(e) => {
                                          setNotchValue("60Hz");
                                        }}
                                      >
                                        60 Hz
                                      </button>
                                    </div>
                                  </div>
                                  <button
                                    className="btn green-btn32 btn-sm"
                                    type="button"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "6",
                                    }}
                                    disabled={requestStatus}
                                    onClick={() => {
                                      applyFilters(lffValue, hffValue, notchValue);
                                    }}
                                  >
                                    Apply Filters
                                  </button>
                            </div>
                            {/* laga howa mal */}
                        </div>
                        <div className='header-view-container'>
                            <img src={tileViewIcon} alt="tile view" onClick={() => toggleGraphView('tile')} className={graphView === 'tile' ? 'header-active-view': ''} />
                            <img src={tableViewIcon} alt="table view" onClick={() => toggleGraphView('table')} className={graphView === 'table' ? 'header-active-view': ''} />
                        </div>
                    </div>
                    <div className='eeg-graph'>
                        <div className="graph-box">
                          <canvas id="chart" ref={myRef} height="200" />
                        </div>
                    </div>
                    <div className='eeg-graph'>
                        <div className="graph-box">
                          <canvas id="chart" ref={myRefCH2} height="200" />
                        </div>
                    </div>
                    
                    <div className='eeg-graph-controls'>
                        <img src={fastBackwardIcon} alt="fast backward"/>
                        <button 
                            disabled={dataCount === 0 ? true : false}
                            className='control-button'
                            onClick={() => {
                            setDataCount(dataCount - 1);
                            setValue([0, 1000]);
                            }}
                        >
                            <img src={backwardIcon} alt="backward"/>
                        </button>
                        <img src={playIcon} alt="play"/>
                        <button
                        disabled={dataCount === dataLength - 1 ? true : false}
                        className='control-button'
                        onClick={() => {
                        setDataCount(dataCount + 1);
                        setValue([0, 1000]);
                        }}
                        >
                            <img src={forwardIcon} alt="forward"/>
                        </button>
                        <img src={fastForwardIcon} alt="fast forward"/>
                    </div>
                </div>
                <div className='qeeg-graph-panel'>
                    <p>QEEG: <span>Spectogram</span></p>
                    <div className='qeeg-graph'>
                        <div className="graph-box">
                            {/* {isHeatMap ? (
                                <img
                                    src={heatMapUrl}
                                    alt="plot"
                                    className="responsive-image" 
                                />
                            ) : (
                                <div
                                    style={{
                                        textAlign: "center",
                                        paddingTop: "2%",
                                    }}
                                >
                                    {" "}
                                    <p>Loading Spectrogram</p>  <ClipLoader />
                                </div>

                            
                            )} */}
                            <div className="App">
                              <h3>Spectrogram Graph</h3>
                              <HeatMapGraph />
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
            {/* <div className='recording-sidebar'>
                <div className='tabs'>
                    <div className={`tab ${activeTab === 'notes' ? 'active-tab': ""}`} onClick={() => toggleActiveTab('notes')}>Notes</div>
                    <div className={`tab ${activeTab === 'qeeg' ? 'active-tab': ""}`} onClick={() => toggleActiveTab('qeeg')}>qEEG</div>
                </div>
                
                {activeTab === "notes" ?
                    <div className='tabs-list'>
                      {tabsListDummyData.map((iten, index) => <div key={index} className='tabs-list-item'>0:03 - Notes</div>)}
                    </div>
                    :
                    <div className='tabs-list'>
                        {tabsListDummyData.map((iten, index) => <div key={index} className='tabs-list-item'>0:01 - EEG Normal</div>)}
                    </div>
                }
            </div> */}
      </div>
    )}
    </div>
    
  )
}

export default Recording2Updated