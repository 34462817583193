import "./App.css";
import UpdatedLogin from "./components/UpdatedLogin";
import VerifyAccount from "./components/VerifyAccount";
import ResetPassword from "./components/ResetPassword"; 
import ResetPasswordConfirm from "./components/ResetPasswordConfirm"; 



import AdminPanelHeader from "./components/AdminPanelHeader";
import Header from "./components/header/Header";
import Dashboard from "./components/Dashboard";
import NewPatient from "./screens/NewPatient";
import PatientVisits from "./components/patient-visits/PatientVisits";
import HomePage from "./screens/HomePage";
import PenDetail from "./screens/PenDetail";
import Test from "./screens/Test";
import RecordingAll from "./components/RecordingAll";
import Epochs from "./components/epochs";
import EpochsVisuals from "./components/EpochsVisuals";
import PaymentPlan from "./components/PaymentPlan";
import PaymentCard from "./components/Card";


import FileUpload from "./components/FileUpload";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import PatientListUpdated from "./components/patient-list-updated/PatientListUpdated";
import DeletedPatientList from "./components/deleted-patient-list/DeletedPatientList";
import DeletedVisitList from "./components/deleted-visit-list/DeletedVisitList";
import SubjectListUpdated from "./components/subject-list-updated/SubjectListUpdated";
import RecordingListUpdated from "./components/recordings-list-updated/RecordingListUpdated";
import RecordingListV1 from "./components/recording-list-v1/RecordingListV1";
import CompareVisit from "./components/compareVisit/CompareVisit";

import StudiesListUpdated from "./components/studies-list-updated/StudiesListUpdated";
import Recording2Updated from "./components/recording2Updated/Recording2Updated";
import Recording2UpdatedV1 from "./components/recording2Updated/Recording2UpdatedV1";

import AccountSetting from "./components/AccountSetting";
import Register from "./components/Register-updated/Register";
import { useEffect } from "react";
import EditPatient from "./components/Tabs/EditPatient";
import FirmwareFormComponent from "./components/firmware/FirmwareFormComponent";
import FirmwareUpdate from "./components/firmware-update/FirmwareUpdate";
import FirmwareList from "./components/firmware-list/FirmwareList";


function App() {
  const stateValue = useSelector((state) => state.loginData.status);
  useEffect(() => {
    // Check if token is available in localStorage
    if (localStorage.token) {
        console.log("Token is available in localStorage");
        let data = localStorage.token.split('.')[1];
        const parsedData = JSON.parse(atob(data));
        const currentDate = new Date();
        const expired = Math.floor((currentDate.getTime())/1000) >= parsedData.exp;

        // Check if token is expired
        if (expired) {
            localStorage.clear();
            window.location.reload();
        }
    }
  }, []);
  

  const ProtectedRoutes = () => {
    if (stateValue === false) {
      return (
        <Switch>
          {/* <Route path="/home" exact>
            <Home></Home>
          </Route> */}

          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path="/technology" exact>
            <PenDetail />
          </Route>

          <Route path="/test" exact>
            <Test />
          </Route>
          {/* <Route path="/reg" exact>
            <Register/>
          </Route> */}

          <Route path="/login" exact>
            <UpdatedLogin></UpdatedLogin>
          </Route>

          <Route path="/verify_account/:token" exact>
            <VerifyAccount></VerifyAccount>
          </Route>

          <Route path="/reset-password" exact>
            <ResetPassword></ResetPassword>
          </Route>

          <Route path="/password-reset/confirm/:uid/:token" exact>
            <ResetPasswordConfirm></ResetPasswordConfirm>
          </Route>

          <Route path="/register" exact>
            <Register/>
          </Route>
          <Route path="/payment" exact>
            <PaymentPlan />
          </Route>
          <Route path="/card" exact>
            <PaymentCard />
          </Route>

          
          dashboard

          <Redirect from="*" to="/" />

        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path="/technology" exact>
            <PenDetail />
          </Route>

          <Route path="/test" exact>
            <Test />
          </Route>

          <Route path="/all-recordings" exact>
            <RecordingAll />
          </Route>

          <Route path="/epochs" exact>
            <Epochs />
          </Route>

          <Route path="/account-settings" exact>
            <AccountSetting />
          </Route>


          <Route path="/epochs-display/:key" exact component={EpochsVisuals} />

          <Route path="/dashboard" exact>
            <Header></Header>
            <PatientListUpdated></PatientListUpdated>
          </Route>

          <Route path="/firmware" exact>
            <Header></Header>
            <FirmwareList></FirmwareList>
          </Route>

          <Route path="/firmware-create" exact>
            <Header></Header>
            <FirmwareFormComponent></FirmwareFormComponent>
          </Route>

          <Route path="/firmware-update/:id" exact>
            <Header></Header>
            <FirmwareUpdate></FirmwareUpdate>
          </Route>

          <Route path="/deleted-patient-list" exact>
            <Header></Header>
            <DeletedPatientList></DeletedPatientList>
          </Route>

          <Route path="/deleted-visit-list" exact>
            <Header></Header>
            <DeletedVisitList></DeletedVisitList>
          </Route>

          <Route path="/edit-patient/:id" exact>
            <Header></Header>
            <EditPatient></EditPatient>
          </Route>

          <Route path="/patient-cards" exact>
            <Header></Header>
            <Dashboard></Dashboard>
          </Route>


          <Route path="/payment" exact>
            <PaymentPlan />
          </Route>

          <Route path="/patients" exact>
            <AdminPanelHeader></AdminPanelHeader>
            <NewPatient />
          </Route>

          <Route path="/listpatients" exact>
            <Header></Header>
            <PatientListUpdated></PatientListUpdated>
          </Route>

          <Route path="/listsubjects" exact>
            <Header></Header>
            <SubjectListUpdated></SubjectListUpdated>
          </Route>

          <Route path="/listrecordings_v0/:id" exact>
            <Header></Header>
            <RecordingListUpdated></RecordingListUpdated>
          </Route>

          <Route path="/listrecordings/:id" exact>
            <Header></Header>
            <RecordingListV1></RecordingListV1>
          </Route>

          <Route path="/compare-visits" exact>
            <Header></Header>
            <CompareVisit></CompareVisit>
          </Route>

          <Route path="/liststudies" exact>
            <Header></Header>
            <StudiesListUpdated></StudiesListUpdated>
          </Route>

          {/* <Route path="/recording/:id" exact component={Recording2} /> */}
          <Route path="/recording_v0/:id" exact>
            <Header/>
            <Recording2Updated />
          </Route>

          <Route path="/recording/:id" exact>
            <Header/>
            <Recording2UpdatedV1 />
          </Route>

          <Route path="/visits/:id" exact component={PatientVisits} />
          <Route path="/fileupload/:id" exact component={FileUpload} />

          <Redirect from="*" to="/dashboard" />
        </Switch>
      );
    }
  };

  return (
    <div className="App">
      <Router>
        {/* <Switch>
          <Route path="/" exact>
            <Home></Home>
          </Route>

          <Route path="/login" exact>
            <Login></Login>
          </Route>

          <Route path="/dashboard" exact>
            <AdminPanelHeader></AdminPanelHeader>
            <Dashboard></Dashboard>
          </Route>

          <Route path="/recording/:id" exact component={Recording2} />

          <Route path="/visits/:id" exact component={PatientVisits} />
        </Switch> */}
        <ProtectedRoutes />
      </Router>
    </div>
  );
}

export default App;
