export const PATIENT_CREATE_REQUEST = 'PATIENT_CREATE_REQUEST'
export const PATIENT_CREATE_SUCCESS = 'PATIENT_CREATE_SUCCESS'
export const PATIENT_CREATE_FAIL = 'PATIENT_CREATE_FAIL'
export const PATIENT_CREATE_RESET = 'PATIENT_CREATE_RESET'


export const PATIENT_LIST_REQUEST = 'PATIENT_LIST_REQUEST'
export const PATIENT_LIST_SUCCESS = 'PATIENT_LIST_SUCCESS'
export const PATIENT_LIST_FAIL = 'PATIENT_LIST_FAIL'

export const PATIENTVISIT_CREATE_REQUEST = 'PATIENTVISIT_CREATE_REQUEST'
export const PATIENTVISIT_CREATE_SUCCESS = 'PATIENTVISIT_CREATE_SUCCESS'
export const PATIENTVISIT_CREATE_FAIL = 'PATIENTVISIT_CREATE_FAIL'
export const PATIENTVISIT_CREATE_RESET = 'PATIENTVISIT_CREATE_RESET'

export const PATIENTVISIT_LIST_REQUEST = 'PATIENTVISIT_LIST_REQUEST'
export const PATIENTVISIT_LIST_SUCCESS = 'PATIENTVISIT_LIST_SUCCESS'
export const PATIENTVISIT_LIST_FAIL = 'PATIENTVIST_LIST_FAIL'
