import axios from 'axios'

import { 
    DOCTOR_LIST_REQUEST,
    DOCTOR_LIST_SUCCESS, 
    DOCTOR_LIST_FAIL, 


} from '../constants/doctorConstants'


export const listDoctors = () => async (dispatch) => {

    try{
        dispatch({type:DOCTOR_LIST_REQUEST })
        const {data} = await axios.get(`http://127.0.0.1:8000/doc/docs/`)
    
        dispatch({
            type:DOCTOR_LIST_SUCCESS,
            payload: data 
        })

    }catch(error){
        dispatch({
            type:DOCTOR_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail, 
        })

    }
}